import styled from 'styled-components';
import { Flex, Box, Link, Text } from 'rebass';
import SocialLinks from './SocialLinks';
import { LteTablet } from '../utils/MediaQueries';

const FooterWrapper = styled(Flex)`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    margin-top: 135px;
    padding: 30px 30px 50px 30px;
    background-color: ${props => props.theme.colorsInvert.background};
    color: ${props => props.theme.colorsInvert.text};
    &:before {
        content: ' ';
        display: inline-block;
        width: 180px;
        height: 130px;
        position: absolute;
        top: -135px;
        right: calc(50% - 360px);
        background-image: url(./images/footer.png);
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: contain;
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        &:before {
            width: 125px;
            height: 80px;
            top: -90px;
            right: 15px;
        }
    }
`;
const Logo = styled(Box)`
    width: 180px;
    height: 70px;
    margin-bottom: 30px !important;
    font-size: ${props => props.theme.fontSizeElements.h2}px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    background-image: url(./images/smudge.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
`;
const Menu = styled(Box)`
    text-align: center;
    @media screen and (${props => (props.theme.breakpoints.lteTabletQuery)}) {
        margin: 0 20px 30px !important;
    }
`;
const MenuItem = styled(Link)`
    position: relative;
    margin-left: 20px !important;
    margin-right: 20px !important;
    text-decoration: none;
    font-size: ${props => props.theme.fontSizeElements.body2}px;
    line-height: 1.8em;
    color: ${props => props.theme.colorsInvert.text};
    &:after {
        content: ' ';
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: -5px;
        background-color: ${props => props.theme.colorsInvert.text};
        opacity: .5;
        transition: ${props => props.theme.animation.transitionBasic};
    }
    &:hover {
        &:after {
            opacity: 1;
        }
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        font-size: ${props => props.theme.fontSizeElements.body1}px;
    }
`;
const Copyright = styled(Text)`
    position: absolute;
    right: 30px;
    bottom: 15px;
    font-size: ${props => props.theme.fontSizeElements.body1}px;
    color: ${props => props.theme.colorsInvert.subtitle};
`;

function Footer() {
    return (
        <FooterWrapper
            px={2}
            alignItems='center'
            id='rumpel-life'>
            <Logo>Rumpel Life</Logo>
            <Menu mx='auto'>
                <MenuItem variant='nav' href='https://twitter.com/rumpelcrew' rel="noreferrer" target="_blank">Rumpelcrew twitter</MenuItem> |            
                <MenuItem variant='nav' href='https://www.instagram.com/rumpel.crew/' rel="noreferrer" target="_blank">Instagram</MenuItem> |       
                <MenuItem variant='nav' href='https://discord.gg/aRNFqQSR46' rel="noreferrer" target="_blank">Discord</MenuItem> |             
                <MenuItem variant='nav' href='https://www.lifeisporno.com/friends/' rel="noreferrer" target="_blank">Life is Porno art collection</MenuItem> |
                <MenuItem variant='nav' href='https://www.lifeisporno.com/article/forbidden-spot/' rel="noreferrer" target="_blank">Forbidden Spot art space</MenuItem>   
            </Menu>
            <LteTablet>
                <SocialLinks
                    instagram='https://www.instagram.com/rumpel.crew/'
                    twitter='https://twitter.com/rumpelcrew'
                    discord='https://discord.gg/aRNFqQSR46'
                    color='light'
                />
            </LteTablet>
            <Copyright>&copy;Copyright 2022 Rumpel</Copyright>
        </FooterWrapper>
    );
}
  
export default Footer;

