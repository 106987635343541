import React from "react";
/* @ts-ignore */
import { slide as Menu } from 'react-burger-menu';
import theme from '../theme/theme';

type PropsHamburgerMenu = {
  pageWrapId: string; 
  outerContainerId: string;
};

var styles = {
  bmBurgerButton: {
    position: 'absolute',
    width: '28px',
    height: '28px',
    right: '30px',
    top: '25px'
  },
  bmBurgerBars: {
    height: '12%',
    background: theme.colorsInvert.text,
  },
  bmBurgerBarsHover: {
    background: theme.colorsInvert.primary,
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    height: '24px',
    background: theme.colorsInvert.text,
  },
  bmCrossHover: {
    background: theme.colorsInvert.primary,
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%'
  },
  bmMenu: {
    background: theme.colorsInvert.background,
    padding: '30px',
    fontSize: theme.fontSizeElements.h3
  },
  bmMorphShape: {
    fill: theme.colorsInvert.text,
  },
  bmItem: {
    display: 'block',
    fontWeight: 'bold',
    fontSize: "30px",
    lineHeight: '1.6em',
    color: theme.colorsInvert.text,
    transform: theme.animation.transitionBasic,
  },
  bmItemHover: {
    textDecoration: 'none',
    color: theme.colorsInvert.primary,
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}

function HamburgerMenu({pageWrapId, outerContainerId}: PropsHamburgerMenu) {
  return (
    // Pass on our props
    <Menu 
      pageWrapId={pageWrapId} 
      outerContainerId={outerContainerId}
      styles={styles}
      right 
      isOpen={false}
    >
      <a className='menu-item' href='#about'>About</a>
      <a className='menu-item' href='#nft-collection'>NFT collection</a>
      <a className='menu-item' href='#team'>Team</a>
      <a className='menu-item' href='#roadmap'>Roadmap</a>
      <a className='menu-item' href='#rumpel-life'>Rumpel life</a>
    </Menu>
  );
};

export default HamburgerMenu;