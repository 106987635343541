import { useState } from 'react';
import styled from 'styled-components';
import { Flex, Heading, Text, Card, Image } from 'rebass';
import ReactCardFlip from 'react-card-flip';
import Info from '../icons/Info';
import Close from '../icons/Close';
import Frame from './Frame';
import SocialLinks from './SocialLinks';
/* @ts-ignore */
import Slider from 'react-slick';

const TeamBox = styled(Frame)`
    margin-bottom: 80px !important;
    h3 {
        text-transform: uppercase;
        text-align: center;
    }
    &:before {
        content: ' ';
        position: absolute;
        background-repeat: no-repeat;
        top: 0px;
        left: 0px;
        width: 35px;
        height: 85px;
        background-image: url(./images/team/01.png);
        background-position: center center;
        background-size: contain;
    }
    @media screen and (${props => (props.theme.breakpoints.tabletQuery)}) {
        margin-bottom: 60px !important;
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        margin-bottom: 40px !important;
    }
`;
const TeamListWrapper = styled(Flex)`
    max-width: ${props => props.theme.layouts.lg}px;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 120px !important;
    @media screen and (${props => (props.theme.breakpoints.gteTabletQuery)}) {
        .slider {
            display: flex;
            flex: 1 0 25%; /* explanation below */
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
        }
    }
    @media screen and (${props => (props.theme.breakpoints.tabletQuery)}) {
        flex: 1 0 33%;
        margin-bottom: 100px !important;
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        flex: 1 0 50%;
        margin-bottom: 80px !important;
    }
`;
const TeamMember = styled(Card)`
    width: 220px;
    min-height: 385px;
    position: relative;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 60px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    text-align: center;
    img {
        max-width: 220px;
        width: auto !important;
        height: 280px;
        object-fit: contain;
        margin: 0 auto !important;
    }
    h4 {
        padding-top: 20px;
        position: relative;
        font-size: ${props => props.theme.fontSizeElements.h4}px;
        font-family: ${props => props.theme.fonts.heading};
        &:before {
            content: ' ';
            display: inline-block;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            width: 100%;
            height: 3px;
            background-image: url(./images/team/10.png);
            background-repeat: no-repeat;
            background-position: top center;
            background-size: contain;
        }
    }
    .card-front,
    .card-back {
        button {
            border: 0 none;
            padding: 0;
            background-color: transparent;
            cursor: pointer;
            svg {
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                transition: ${props => props.theme.animation.transitionBasic}
            }
            &:hover {
                svg {
                    color: ${props => props.theme.colorsInvert.primary} !important;
                    fill: ${props => props.theme.colorsInvert.primary} !important; 
                }
            }
        }
    }
    .card-front {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        line-height: 1.5em;
        p {
            font-size: ${props => props.theme.fontSizeElements.body2}px;
            line-height: 1.75em;
        }
    }
    .card-back {
        p {
            padding: 10px;
            font-size: ${props => props.theme.fontSizeElements.caption}px;
            line-height: 1.5em;
        }
    }
    @media screen and (${props => (props.theme.breakpoints.tabletQuery)}) {
        width: 175px;
        min-height: 300px;
        margin-bottom: 40px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        img {
            max-width: 175px;
            height: 220px;
        }
        h4 {
            padding-top: 20px;
            font-size: ${props => props.theme.fontSizeElements.h4}px;
        }
        .card-front {
            p {
                font-size: ${props => props.theme.fontSizeElements.body1}px;
            }
        }
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        width: 130px;
        min-height: 210px;
        margin-bottom: 30px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        img {
            max-width: 130px;
            height: 160px;
        }
        h4 {
            padding-top: 15px;
            font-size: ${props => props.theme.fontSizeElements.h5}px;
        }
        .card-front {
            p {
                font-size: ${props => props.theme.fontSizeElements.caption}px;
            }
        }
    }
`;
const SliderWrapper = styled(Slider)`
    max-width: 100vw;
    width: calc(100% - 60px);
    margin-left: 30px;
    margin-right: 30px;
    .slick-prev,
    .slick-next {
        width: 32px;
        height: 32px;
        &:before {
            font-size: 32px;
            color: ${props => props.theme.colors.text} !important;
            transition: ${props => props.theme.animation.transitionBasic};
        }
        &:hover {
            &:before {
                color: ${props => props.theme.colors.primary} !important;
            }
        }
    }
    .slick-prev {
        &:before {
            content: '<'
        }
    }
    .slick-next {
        &:before {
            content: '>'
        }
    }
`;

var settings = {
    dots: true,
    infinite: true,
    arrows: true,
    rows: 2,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
        {
            breakpoint: 9999,
            settings: "unslick"
        },
        {
            breakpoint: 767,
            settings: {
            }
        }
    ]
};

function TeamList() {
    const [isFlipped, setIsFlipped] = useState({ flipped: new Set()});     
    const handleClick = (id: number) => {
        return (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            let flipped = new Set(isFlipped.flipped);
            if (flipped.has(id)) {
              flipped.delete(id);
            } else {
              flipped.add(id);
            }
            setIsFlipped({ flipped });
            // setIsFlipped(previousState => !previousState);
        };
    }
    return (
        <>
            <TeamBox id="team">
                <Heading
                    as='h3'
                    mb='30px'>
                    Team
                </Heading>
                <Text as='p'>
                The Rumpel NFTeam is <strong>fully doxed</strong> and has a <strong>track record</strong> in many different fields. We've been running <a href='https://www.instagram.com/lifeisporno' rel="noreferrer" target="_blank"><strong>a successful streetwear brand</strong></a> with always-sold-out collections for the past 10 years. Running a creative <a href='https://www.instagram.com/forbiddenspot/' rel="noreferrer" target="_blank"><strong>art space Forbidden Spot</strong></a>. And <strong>collaborating with dozens of brands, artists, and designers</strong>.
                </Text>
            </TeamBox>
            <TeamListWrapper>
                <SliderWrapper {...settings}>
                    <TeamMember width={[ 1/2, 1/3, 1/4 ]}>
                        <ReactCardFlip isFlipped={isFlipped.flipped.has(1)} flipDirection="horizontal">
                            <div className='card-front'>
                                <Image src='./images/team/02.png' alt='Tino Forbidden' onClick={handleClick(1)}/>
                                <button onClick={handleClick(1)}><Info /></button>
                                <Heading as='h4'>Tino Forbidden</Heading>
                                <Text as='p'>(Founder)</Text>
                                <SocialLinks 
                                    iconSize='sm' 
                                    twitter='https://twitter.com/tinoforbidden' 
                                    instagram='https://instagram.com/tinoforbidden'
                                />
                            </div>
                            <div className='card-back' onClick={handleClick(1)}>
                                <button onClick={handleClick(1)}><Close /></button>
                                <Text as='p'>
                                    Tino is an entrepreneur behind many successful projects. He co-founded the art collective and the streetwear brand Life is Porno in 2012, as well as the art space Forbidden Spot and an award-winning gastronomy experience Forbidden Taste.
                                    <br /><br />
                                    Tino is the author of the book FORBIDDEN and used to work in Global Marketing at Red Bull Headquarters. He's spent over a year in the NFT rabbit hole and is a proud member of many NFT communities, such as Doodles, Adam Bomb Squad, VeeFriends, the littles, Woodies (as well as many degen projects).
                                </Text>
                            </div>
                        </ReactCardFlip>
                    </TeamMember>
                    <TeamMember width={[ 1/2, 1/3, 1/4 ]}>
                        <ReactCardFlip isFlipped={isFlipped.flipped.has(2)} flipDirection="horizontal">
                            <div className='card-front'>
                                <Image src='./images/team/03.png' alt='Kacer' onClick={handleClick(2)}/>
                                <button onClick={handleClick(2)}><Info /></button>
                                <Heading as='h4'>Kacer</Heading>
                                <Text as='p'>(Artist)</Text>
                                <SocialLinks 
                                    iconSize='sm' 
                                    instagram='https://www.instagram.com/rumpel.crew'
                                />
                            </div>
                            <div className='card-back' onClick={handleClick(2)}>
                                <button onClick={handleClick(2)}><Close /></button>
                                <Text as='p'>
                                    Kacer created the Rumpel character around 2008 and is one of the founding members of the art collective. He has quite a few successes up his sleeves but just hates to be talked about. And he creates, instead of being active on social media.
                                    <br /><br />
                                    Kacer hand-painted every single Rumpel from this NFT collection. Many were taken from old street arts or sketchbooks - and upgraded.
                                </Text>
                            </div>
                        </ReactCardFlip>
                    </TeamMember>
                    <TeamMember width={[ 1/2, 1/3, 1/4 ]}>
                        <ReactCardFlip isFlipped={isFlipped.flipped.has(3)} flipDirection="horizontal">
                            <div className='card-front'>
                                <Image src='./images/team/04.png' alt='Dian' onClick={handleClick(3)}/>
                                <button onClick={handleClick(3)}><Info /></button>
                                <Heading as='h4'>Dian</Heading>
                                <Text as='p'>(Artist)</Text>
                                <SocialLinks 
                                    iconSize='sm'
                                    instagram='https://www.instagram.com/colorsbydian'
                                />
                            </div>
                            <div className='card-back' onClick={handleClick(3)}>
                                <button onClick={handleClick(3)}><Close /></button>
                                <Text as='p'>
                                    Dian is a street artist who co-founded the Life is Porno brand as well as the Forbidden Spot art space. He has painted murals all over the world and his signature elephants can be found anywhere from NYC, through Europe, all the way to Africa. His stop-frame animations (two of them starring Rumpel) went viral and won several international short film awards, too.
                                    <br /><br />
                                    He's the brand's creative director and has collaborated with many different brands, designers, street artists, and graffiti writers.
                                </Text>
                            </div>
                        </ReactCardFlip>
                    </TeamMember>
                    <TeamMember width={[ 1/2, 1/3, 1/4 ]}>
                        <ReactCardFlip isFlipped={isFlipped.flipped.has(4)} flipDirection="horizontal">
                            <div className='card-front'>
                                <Image src='./images/team/05.png' alt='Dobby Labs' onClick={handleClick(4)}/>
                                <button onClick={handleClick(4)}><Info /></button>
                                <Heading as='h4'>DOBBY LABS</Heading>
                                <Text as='p'>(Dev Team)</Text>
                                <SocialLinks 
                                    iconSize='sm' 
                                    web='https://www.dobbylabs.com/'
                                />
                            </div>
                            <div className='card-back' onClick={handleClick(4)}>
                                <button onClick={handleClick(4)}><Close /></button>
                                <Text as='p'>
                                    The mint page as well as the smart contracts are in the hands of the DOBBY LABS. They are a team of skilled blockchain developers behind many successful projects.
                                </Text>
                            </div>
                        </ReactCardFlip>
                    </TeamMember>
                    <TeamMember width={[ 1/2, 1/3, 1/4 ]}>
                        <ReactCardFlip isFlipped={isFlipped.flipped.has(5)} flipDirection="horizontal">
                            <div className='card-front'>
                                <Image src='./images/team/06.png' alt='David Vávra' onClick={handleClick(5)}/>
                                <button onClick={handleClick(5)}><Info /></button>
                                <Heading as='h4'>David Vávra</Heading>
                                <Text as='p'>(Operations)</Text>
                            </div>
                            <div className='card-back' onClick={handleClick(5)}>
                                <button onClick={handleClick(5)}><Close /></button>
                                <Text as='p'>
                                    David is in charge of the operations and is the bridge between the creativity side and the IT side of the project. He's doing the same for the Life is Porno brand and had previously been part of the Prusa Research 3D-tech company.
                                </Text>
                            </div>
                        </ReactCardFlip>
                    </TeamMember>
                    <TeamMember width={[ 1/2, 1/3, 1/4 ]}>
                        <ReactCardFlip isFlipped={isFlipped.flipped.has(6)} flipDirection="horizontal">
                            <div className='card-front'>
                                <Image src='./images/team/07.png' alt='Lucas Pegas' onClick={handleClick(6)}/>
                                <button onClick={handleClick(6)}><Info /></button>
                                <Heading as='h4'>Lucas Pegas</Heading>
                                <Text as='p'>(Graphic Design)</Text>
                                <SocialLinks 
                                    iconSize='sm' 
                                    instagram='https://www.instagram.com/lucaspegas'
                                />
                            </div>
                            <div className='card-back' onClick={handleClick(6)}>
                                <button onClick={handleClick(6)}><Close /></button>
                                <Text as='p'>
                                    Lucas is a graphic designer whose role was to bring all the hand-painted Rumpels into digital life. He's a freelancer and a full-time Rumpel.
                                </Text>
                            </div>
                        </ReactCardFlip>
                    </TeamMember>
                    <TeamMember width={[ 1/2, 1/3, 1/4 ]}>
                        <ReactCardFlip isFlipped={isFlipped.flipped.has(7)} flipDirection="horizontal">
                            <div className='card-front'>
                                <Image src='./images/team/08.png' alt='Viktor Knis' onClick={handleClick(7)}/>
                                <button onClick={handleClick(7)}><Info /></button>
                                <Heading as='h4'>Viktor Knis</Heading>
                                <Text as='p'>(Community Manager)</Text>
                                <SocialLinks 
                                    iconSize='sm' 
                                    twitter='https://twitter.com/vik232323'
                                />
                            </div>
                            <div className='card-back' onClick={handleClick(7)}>
                                <button onClick={handleClick(7)}><Close /></button>
                                <Text as='p'>
                                    Viktor is an NFT OG and a journalist. He'll take care of any of your issues and questions not only around Rumpel NFT project but also NFTs in general.
                                </Text>
                            </div>
                        </ReactCardFlip>
                    </TeamMember>
                    <TeamMember width={[ 1/2, 1/3, 1/4 ]}>
                        <ReactCardFlip isFlipped={isFlipped.flipped.has(8)} flipDirection="horizontal">
                            <div className='card-front'>
                                <Image src='./images/team/09.png' alt='JasoNFT' onClick={handleClick(8)}/>
                                <button onClick={handleClick(8)}><Info /></button>
                                <Heading as='h4'>JasoNFT</Heading>
                                <Text as='p'>(Discord)</Text>
                                <SocialLinks 
                                    iconSize='sm' 
                                    twitter='https://twitter.com/JasoNFT_'
                                />
                            </div>
                            <div className='card-back' onClick={handleClick(8)}>
                                <button onClick={handleClick(8)}><Close /></button>
                                <Text as='p'>
                                    Jason is in charge of our Discord and its security. He's a skilled NFT community manager and a professional writer.
                                </Text>
                            </div>
                        </ReactCardFlip>
                    </TeamMember>
                    <TeamMember width={[ 1/2, 1/3, 1/4 ]}>
                        <ReactCardFlip isFlipped={isFlipped.flipped.has(9)} flipDirection="horizontal">
                            <div className='card-front'>
                                <Image src='./images/team/11.png' alt='NoQik' onClick={handleClick(9)}/>
                                <button onClick={handleClick(9)}><Info /></button>
                                <Heading as='h4'>NoQik</Heading>
                                <Text as='p'>(Discord Mod & Collabs)</Text>
                                <SocialLinks 
                                    iconSize='sm' 
                                    twitter='https://twitter.com/NoQik'
                                />
                            </div>
                            <div className='card-back' onClick={handleClick(9)}>
                                <button onClick={handleClick(9)}><Close /></button>
                                <Text as='p'>
                                Web2 veteran & web3 enthusiast. Helping build communities not only at Rumpel NFT project, but also at Divergents and The Weirdos NFT.
                                </Text>
                            </div>
                        </ReactCardFlip>
                    </TeamMember>
                </SliderWrapper>
            </TeamListWrapper>
        </>
    );
}
  
export default TeamList;

