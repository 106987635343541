import styled from 'styled-components';
import { Flex, Box, Heading, Text, Button } from 'rebass';
import Marquee from "react-fast-marquee";
// @ts-ignore
import VideoPlayer from "react-background-video-player";
import { LteTablet } from '../utils/MediaQueries';
import { backgroundTransitions } from "react-hover-css";

const HeroeWrapper = styled(Flex)`
    width: 100%;
    height: 1080px;
    overflow: hidden;
    position: relative;
    padding-top: 120px !important;
    text-align: center;
    background-color: ${props => props.theme.colorsInvert.background};
    background-image: url(./images/motive.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    &:before {
        content: ' ';
        z-index: 1;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: ${props => props.theme.colorsInvert.background};
        opacity: .5;
    }
    .video {
        z-index: 0;
    }
    @media screen and (${props => (props.theme.breakpoints.tabletQuery)}) {
        padding-top: 100px !important;
        height: 860px;
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        height: 580px;
        padding-top: 80px !important;
    }
`;
const HeadingWrapper = styled(Heading)`
    font-family: ${props => props.theme.fonts.heading};
    font-size: ${props => props.theme.fontSizeElements.h1}px;
    margin-bottom: 90px;
    line-height: 1.75em;
    color: ${props => props.theme.colorsInvert.text};
    @media screen and (${props => (props.theme.breakpoints.tabletQuery)}) {
        margin: 30px 0 60px;
        font-size: ${props => props.theme.fontSizeElements.h1}px;
        line-height: 1.6em;
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        margin: 20px 0 40px;
        font-size: ${props => props.theme.fontSizeElements.h2}px;
        line-height: 1.4em;
    }
`;
const ZIndex1 = styled(Box)`
    z-index: 2;
`;
const MarqueeWrapper = styled(Text)`
    display: flex;
    justify-content: space-between;
    font-weight: normal;
    font-size: ${props => props.theme.fontSizeElements.h2}px;
    color: ${props => props.theme.colorsInvert.text};
    span {
        position: relative;
        padding-left: 16px;
        margin-right: 50px;
        &:before {
            content: ' ';
            display: inline-block;
            width: 6px;
            height: 6px;
            position: absolute;
            top: calc(50% - 3px);
            left: 0;
            border-radius: 50%;
            background-color: ${props => props.theme.colors.danger};
        }
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        font-size: ${props => props.theme.fontSizeElements.h3}px;
    }
`;

const CtaButton = styled(Button)`
    ${backgroundTransitions.hvrSweepToRight}
    z-index: 2;
    position: relative;
    top: 150px;
    padding: 15px 30px !important;
    font-weight: bold;
    font-family: ${props => props.theme.fonts.body};
    font-size: ${props => props.theme.fontSizeElements.body2}px !important;
    border-radius: 3px !important;
    background-color: ${props => props.theme.colors.primary} !important;
    cursor: pointer;
    &:before {
        background-color: ${props => props.theme.colors.primaryActive};
    }
    @media screen and (${props => (props.theme.breakpoints.tabletQuery)}) {
        top: 64px;
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        top: 28px; 
    }
`;

function Heroe() {
    return (
        <HeroeWrapper
            alignItems='center'
            justifyContent='flex-start'
            flexDirection='column'>
            <VideoPlayer
                className="video"
                src={"./video/intro.mp4"}
                autoPlay={true}
                muted={true}
            />
            <ZIndex1>
                <HeadingWrapper as='h1'>
                    Rumpel.<br />
                    A bad-ass <LteTablet><br /></LteTablet> 
                    street art <LteTablet><br /></LteTablet> 
                    character <LteTablet><br /></LteTablet> 
                    entering NFTs.
                </HeadingWrapper>
                <MarqueeWrapper>
                    <Marquee speed={100} gradient={false}>
                        <span>A fully doxed team</span>
                        <span>Strong IRL community</span>
                        <span>Backed by a successful streetwear brand</span>
                        <span>Utility on steroids</span>
                    </Marquee>
                </MarqueeWrapper>
            </ZIndex1>
        </HeroeWrapper>
    );
}
  
export default Heroe;

