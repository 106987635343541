import styled from 'styled-components';
import { Flex, Box, Link, Image } from 'rebass';
import SocialLinks from './SocialLinks';
import { GteDesktop } from '../utils/MediaQueries';

const Topbar = styled(Flex)`
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: ${props => props.theme.layouts.xl};
    height: 120px;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    background-color: transparent;
    color: ${props => props.theme.colorsInvert.text};
    @media screen and (${props => (props.theme.breakpoints.tabletQuery)}) {
        height: 100px;
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        height: 80px;
    }
`;
const Logo = styled(Image)`
    width: 225px;
    height: 87px;
    @media screen and (${props => (props.theme.breakpoints.tabletQuery)}) {
        width: 187px;
        height: 72px;
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        width: 150px;
        height: 58px;
    }
`;
const Menu = styled(Box)`
    display: none;
    @media screen and (${props => (props.theme.breakpoints.gteDesktopQuery)}) {
        display: block;
    }
`;
const MenuItem = styled(Link)`
    margin-left: 20px !important;
    margin-right: 20px !important;
    text-transform: uppercase;
    text-decoration: none;
    font-size: ${props => props.theme.fontSizeElements.body2}px;
    color: ${props => props.theme.colorsInvert.text};
    transition: ${props => props.theme.animation.transitionBasic};
    &:hover {
        color: ${props => props.theme.colorsInvert.primary};
    }
`;


function Navbar() {
    return (
        <Topbar
            px={2}
            alignItems='center'>
            <Link href='/'>
                <Logo src='./images/logo.png' alt='Rumpel' />
            </Link>
            <GteDesktop>
                <>
                    <Menu mx='auto'>
                        <MenuItem variant='nav' href='#about'>About</MenuItem>                
                        <MenuItem variant='nav' href='#nft-collection'>NFT collection</MenuItem>                
                        <MenuItem variant='nav' href='#team'>Team</MenuItem>                 
                        <MenuItem variant='nav' href='#roadmap'>Roadmap</MenuItem>     
                        <MenuItem variant='nav' href='#rumpel-life'>Rumpel life</MenuItem>         
                    </Menu>
                    <SocialLinks
                        instagram='https://www.instagram.com/rumpel.crew/'
                        twitter='https://twitter.com/rumpelcrew'
                        discord='https://discord.gg/aRNFqQSR46'
                        color='light'
                    />
                </>
            </GteDesktop>
        </Topbar>
    );
}
  
export default Navbar;

