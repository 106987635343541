import styled from 'styled-components';
import { Heading, Text, Box, Button } from 'rebass';
import Frame from './Frame';

const NFTBox = styled(Frame)`
    h3 {
        text-transform: uppercase;
    }
    &:before,
    &:after {
        content: ' ';
        position: absolute;
        background-repeat: no-repeat;
    }
    &:before {
        left: -300px;
        top: 90px;
        width: 150px;
        height: 80px;
        background-image: url(./images/nft/06.png);
        background-position: center center;
        background-size: contain;
    }
    &:after {
        right: -75px;
        bottom: 55px;
        width: 75px;
        height: 235px;
        background-image: url(./images/nft/07.png);
        background-position: center center;
        background-size: contain;
    }
    @media screen and (${props => (props.theme.breakpoints.tabletAndDesktopQuery)}) {
        &:before {
            top: -35px;
            left: 50%;
        }
    }
    @media screen and (${props => (props.theme.breakpoints.lteTabletQuery)}) {
        &:after {
            display: none;
        }
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        &:before {
            width: 80px;
            height: 42px;
            top: -19px;
            left: 50%;
        }
    }
`;
const Animation = styled(Box)`
    display: flex;
    justify-content: space-between;
    max-width: 910px;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 40px !important;
    @media screen and (${props => (props.theme.breakpoints.tabletQuery)}) {
        img {
            &:nth-child(1) { height: calc(265px / 1.25); }
            &:nth-child(2) { height: calc(240px / 1.25); }
            &:nth-child(3) { height: calc(200px / 1.25); }
            &:nth-child(4) { height: calc(215px / 1.25); }
            &:nth-child(5) { height: calc(235px / 1.25); }      
        }
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        img {
            &:nth-child(1) { height: calc(265px / 2.5); }
            &:nth-child(2) { height: calc(240px / 2.5); }
            &:nth-child(3) { height: calc(200px / 2.5); }
            &:nth-child(4) { height: calc(215px / 2.5); }
            &:nth-child(5) { height: calc(235px / 2.5); }      
        }
    }
`;
const Graphics = styled(Box)`
    z-index: 1;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1280px;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 40px !important;
    img {
        &:nth-child(2) { margin-top: -50px; }
        &:nth-child(3) { margin-top: 40px; }
        &:nth-child(5) { margin-top: -45px; }
        &:nth-child(6) { margin-top: 30px; }
        &:nth-child(7) { margin-top: -20px; }
    }
    @media screen and (${props => (props.theme.breakpoints.tabletQuery)}) {
        img {
            &:nth-child(1) { 
                height: calc(300px / 1.25);
            }
            &:nth-child(2) { 
                height: calc(250px / 1.25);
                margin-top: calc(-50px / 1.25); 
            }
            &:nth-child(3) { 
                height: calc(275px / 1.25);
                margin-top: calc(40px / 1.25); 
            }
            &:nth-child(4) { 
                height: calc(150px / 1.25);
            }
            &:nth-child(5) { 
                height: calc(240px / 1.25);
                margin-top: calc(-45px / 1.25); 
            }
            &:nth-child(6) { 
                height: calc(290px / 1.25);
                margin-top: calc(30px / 1.25); 
            }
            &:nth-child(7) { 
                height: calc(225px / 1.25);
                margin-top: calc(-20px / 1.25); 
            }
        }
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        padding-top: 130px;
        margin-bottom: 0 !important;
        img {
            &:nth-child(1) { 
                height: calc(300px / 2.25);
            }
            &:nth-child(2) { 
                height: calc(250px / 2.25);
                margin-top: calc(-50px / 2.25); 
            }
            &:nth-child(3) { 
                height: calc(275px / 2.25);
                margin-top: calc(40px / 2.25); 
            }
            &:nth-child(4) { 
                height: calc(150px / 2.25);
            }
            &:nth-child(5) { 
                height: calc(240px / 2.25);
                margin-top: calc(-45px / 2.25); 
            }
            &:nth-child(6) { 
                height: calc(290px / 2.25);
                margin-top: calc(30px / 2.25); 
            }
            &:nth-child(7) { 
                height: calc(225px / 2.25);
                margin-top: calc(-20px / 2.25); 
            }
        }
    }
`;

function NFT() {
    return (
        <>
            <Animation>
                <img src="./images/nft/01.png" height="265" alt="animation-1" />
                <img src="./images/nft/02.png" height="240" alt="animation-2" />
                <img src="./images/nft/03.png" height="200" alt="animation-3" />
                <img src="./images/nft/04.png" height="215" alt="animation-4" />
                <img src="./images/nft/05.png" height="235" alt="animation-5" />
            </Animation>
            <NFTBox id="nft-collection">
                <Heading
                    as='h3'
                    mb='30px'>
                    NFT collection
                </Heading>
                <Text as='p'>
                    This is a new beginning. I've been raised in the streets and I'm ready to grow up and have some fun in the Metaverse. There will be <strong>4321 of us Rumpels on the Ethereum blockchain</strong>. Every single character is <strong>hand-painted</strong> and there are <strong>161 rares and 1-of-1s</strong>.
                    <br /><br />
                    This is first of all an art project. But the utility connected to different traits will surprise you. We call them <strong>Rumpel Experiences</strong>. But you know what? We always rather underpromise and overdeliver. We have <strong>a huge network of artists, musicians, DJs, photographers</strong>, and of course <strong>athletes</strong> from all over the world. Not to forget <strong>chefs, bartenders, and baristas</strong>. And we can't wait to connect the dots.
                </Text>
            </NFTBox>
            <Graphics>
                <img src="./images/nft/08.png" height="300" alt="graphics-1" />
                <img src="./images/nft/09.png" height="250" alt="graphics-2" />
                <img src="./images/nft/10.png" height="275" alt="graphics-3" />
                <img src="./images/nft/11.png" height="150" alt="graphics-4" />
                <img src="./images/nft/12.png" height="240" alt="graphics-5" />
                <img src="./images/nft/13.png" height="290" alt="graphics-6" />
                <img src="./images/nft/14.png" height="225" alt="graphics-7" />
            </Graphics>
        </>
    );
}
  
export default NFT;

