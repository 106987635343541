import styled from 'styled-components';
import { Heading, Text } from 'rebass';
import Frame from './Frame';

const StoryBox = styled(Frame)`
    z-index: 2;
    margin-top: -170px !important;
    margin-bottom: calc(70px + 30px) !important;
    &:before,
    &:after {
        content: ' ';
        position: absolute;
        background-repeat: no-repeat;
    }
    &:before {
        right: -120px;
        bottom: 0;
        width: 120px;
        height: 100%;
        background-image: url(./images/about/01.png);
        background-position: bottom left;
        background-size: contain;
    }
    &:after {
        left: 0;
        right: 0;
        bottom: -30px;
        width: 100%;
        height: 30px;
        background-image: url(./images/about/02.png);
        background-position: 15% center;
        background-size: contain;
    }
    @media screen and (${props => (props.theme.breakpoints.tabletQuery)}) {
        margin-top: -50px !important;
        &:before {
            z-index: -1;
            width: 125px;
            height: 150px;
            top: -150px;
            bottom: auto;
            right: 30px;
            background-size: cover;
            background-position: top left;
        }
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        margin-top: -25px !important;
        &:before {
            z-index: -1;
            width: 75px;
            height: 95px;
            top: -95px;
            bottom: auto;
            right: 15px;
            background-size: cover;
            background-position: top left;
        }
    }
`;

function Story() {
    return (
        <StoryBox id="about">
            <Heading
                as='h3'
                mb='30px'>
                I was born in the streets.
            </Heading>
            <Text as='p'>
                And as a street art character, you're lucky enough to <strong>travel the world</strong>: New York, Paris, Amsterdam, London, you name it. And of course, my hometown Prague.
                <br /><br />
                <strong>My creator is an artist called Kacer.</strong> They say he's directed quite a few music videos and commercials, but I also heard he's a lawyer. Honestly, I don't care. I'm lucky that he hand-painted me and my entire crew and we can continue our street art journey onto the blockchain.
                <br /><br />
                <strong>The art collective </strong><a href='https://lifeisporno.com' rel="noreferrer" target="_blank"><strong>Life is Porno </strong></a> has also put me in a nice spot. I was featured on their collections in many creative ways. Can you imagine that 1000s of people are wearing me on their heads? <strong>And many of them even have a tattoo</strong> of me on their skin? Sick.
            </Text>
        </StoryBox>
    );
}
  
export default Story;

