import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import theme from './theme/theme';
import './styles/style.css';
import HamburgerMenu from './components/HamburgerMenu';
import Navbar from './components/Navbar';
import Heroe from './components/Heroe';
import Story from './components/Story';
import VideoList from './components/VideoList';
import NFT from './components/NFT';
import TeamList from './components/TeamList';
import RoadmapList from './components/RoadmapList';
import Footer from './components/Footer';
import { LteTablet } from './utils/MediaQueries';
import Fold from './components/Fold'

ReactDOM.render(
    // <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Fold />
            <div id="outer-container">
                <LteTablet>
                    <HamburgerMenu pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
                </LteTablet>
                <main id="page-wrap">
                    <Navbar />
                    <Heroe />
                    <Story />
                    <VideoList />
                    <NFT />
                    <TeamList />
                    <RoadmapList />
                    <Footer />
                </main>
            </div>
        </ThemeProvider>,
    // </React.StrictMode>,
    document.getElementById('root')
);