const theme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
    mobile: 767,
    tablet: 768,
    desktop: 992,
    desktopLg: 1400,
    mobileQuery: "max-width: 767px",
    tabletQuery: "min-width: 768px) and (max-width: 991px",
    gteTabletQuery: "min-width: 768px",
    lteTabletQuery: "max-width: 991px",
    tabletAndDesktopQuery: "min-width: 768px) and (max-width: 1399px",
    desktopQuery: "min-width: 992px) and (max-width: 1399px",
    gteDesktopQuery: "min-width: 992px",
    lteDesktopQuery: "max-width: 1399px",
    desktopLgQuery: "min-width: 1400px",
  },
  layouts: {
    lg: 1200, // 1366
    xl: 1400, // 1536
  },
  fonts: {
    body: 'Akrobat, Arial, Helvetica, sans-serif',
    heading: 'Akrobat, Arial, Helvetica, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [14, 16, 18, 20, 24, 28, 32, 40],
  fontSizeElements: {
    h1: 48,
    h2: 36,
    h3: 28,
    h4: 22,
    h5: 18,
    h6: 16,
    subtitle1: 28,
    subtitle2: 22,
    body1: 18,
    body2: 20,
    button: 18,
    caption: 14,
  },
  colors: {
    text: '#000000',
    subtitle: '#bebebe',
    border: '#000000',
    background: '#ffffff',
    primary: '#c6162c',
    primaryActive: '#941022',
    danger: '#e42528',
  },
  colorsInvert: {
    text: '#ffffff',
    subtitle: '#bebebe',
    border: '#ffffff',
    background: '#000000',
    primary: '#c6162c',
    primaryActive: '#941022',
    danger: '#e42528',
  },
  animation: {
    transitionBasic: 'all 0.3s ease',
  }
}

export default theme;