import React from 'react';
import IconBase from 'react-icon-base';

export default function Icon(props: any)  {
  return (
    <IconBase
      {...props}
      viewBox={"0 0 46.35 46.34"}
      dangerouslySetInnerHTML={{
        __html: `<path class="icon" d="M23.18,11.29A11.88,11.88,0,1,0,35.06,23.17,11.86,11.86,0,0,0,23.18,11.29Zm0,19.6a7.72,7.72,0,1,1,7.72-7.72,7.73,7.73,0,0,1-7.72,7.72ZM38.32,10.8A2.77,2.77,0,1,1,35.55,8,2.76,2.76,0,0,1,38.32,10.8Zm7.87,2.82c-.18-3.72-1-7-3.75-9.71S36.45.35,32.73.16,17.44,0,13.62.16s-7,1-9.71,3.74S.35,9.89.16,13.6,0,28.9.16,32.72s1,7,3.75,9.71,6,3.56,9.71,3.75,15.29.21,19.11,0,7-1,9.71-3.75,3.56-6,3.75-9.71.21-15.28,0-19.1ZM41.24,36.83a7.75,7.75,0,0,1-4.4,4.4c-3.05,1.21-10.29.93-13.66.93s-10.62.27-13.66-.93a7.75,7.75,0,0,1-4.4-4.4c-1.21-3-.93-10.29-.93-13.66s-.27-10.62.93-13.66a7.75,7.75,0,0,1,4.4-4.4c3.05-1.21,10.29-.94,13.66-.94s10.62-.26,13.66.94a7.75,7.75,0,0,1,4.4,4.4c1.21,3.05.93,10.29.93,13.66S42.45,33.79,41.24,36.83Z"/>`,
      }}
    />
  );
};
