import { ReactElement } from 'react';
import { useMediaQuery } from 'react-responsive';
import theme from '../theme/theme'

/*
lt < (less than)
gt > (greater than)
eq == (equal to)
ne != (not equal to)
gte >= (greater than or equal to)
lte <= (less than or equal to)
*/

type Props = {
    children: ReactElement;
};

export const Xs = ({ children }: Props) => {
    const resolution = useMediaQuery({ maxWidth: theme.breakpoints.sm - 1 })
    return resolution ? children : null
}
export const Sm = ({ children }: Props) => {
    const resolution = useMediaQuery({ minWidth: theme.breakpoints.sm, maxWidth: theme.breakpoints.md - 1 })
    return resolution ? children : null
}
export const Md = ({ children }: Props) => {
    const resolution = useMediaQuery({ minWidth: theme.breakpoints.md, maxWidth: theme.breakpoints.lg - 1 })
    return resolution ? children : null
}
export const Lg = ({ children }: Props) => {
    const resolution = useMediaQuery({ minWidth: theme.breakpoints.lg, maxWidth: theme.breakpoints.xl - 1 })
    return resolution ? children : null
}
export const Xl = ({ children }: Props) => {
    const resolution = useMediaQuery({ minWidth: theme.breakpoints.xl, maxWidth: theme.breakpoints.xxl - 1 })
    return resolution ? children : null
}
export const Xxl = ({ children }: Props) => {
    const resolution = useMediaQuery({ minWidth: theme.breakpoints.xxl })
    return resolution ? children : null
}
export const Mobile = Xs;
export const Tablet = Md;
export const LteTablet = ({ children }: Props) => {
    const resolution = useMediaQuery({ maxWidth: theme.breakpoints.lg - 1 })
    return resolution ? children : null
}
export const GteTablet = ({ children }: Props) => {
    const resolution = useMediaQuery({ minWidth: theme.breakpoints.md })
    return resolution ? children : null
}
export const Desktop = ({ children }: Props) => {
    const resolution = useMediaQuery({ minWidth: theme.breakpoints.lg, maxWidth: theme.breakpoints.xxl - 1 })
    return resolution ? children : null
}
export const GteDesktop = ({ children }: Props) => {
    const resolution = useMediaQuery({ minWidth: theme.breakpoints.lg })
    return resolution ? children : null
}
export const DesktopLarge = Xxl;