import styled from 'styled-components';
import { Box } from 'rebass';

const Frame = styled(Box)`
    width: 100%;
    max-width: 690px;
    position: relative;
    margin: 0 auto !important;
    padding: 30px;
    border: 1px solid ${props => props.theme.colors.border};
    box-shadow: 5px 5px ${props => props.theme.colors.border};
    background-color: ${props => props.theme.colors.background};
    h3 {
        margin-bottom: 0;
        font-size: ${props => props.theme.fontSizeElements.h3}px;
        font-family: ${props => props.theme.fonts.heading};
    }
    p {
        font-size: ${props => props.theme.fontSizeElements.body2}px;
        line-height: 1.75em;
    }
    h3 + P {
            margin-top: 30px;
    }
    @media screen and (${props => (props.theme.breakpoints.tabletQuery)}) {
        padding: 25px;
        p {
            font-size: ${props => props.theme.fontSizeElements.body1}px;
        }
        h3 + P {
            margin-top: 20px;
        }
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        width: calc(100% - 20px);
        margin-left: 10px !important;
        margin-right: 10px !important;
        padding: 20px;
        h3 {
            font-size: ${props => props.theme.fontSizeElements.h4}px;
        }
        p {
            font-size: ${props => props.theme.fontSizeElements.caption}px;
        }
        h3 + P {
            margin-top: 15px;
        }
    }
`;

export default Frame;