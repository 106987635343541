import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import Video from './Video';

const VideoListWrapper = styled(Flex)`
    max-width: ${props => props.theme.layouts.xl}px;
    justify-content: space-between;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 120px !important;
    padding-left: 70px;
    padding-right: 70px;
    @media screen and (${props => (props.theme.breakpoints.tabletQuery)}) {
        padding-left: 50px;
        padding-right: 50px;
    }
    @media screen and (${props => (props.theme.breakpoints.lteDesktopQuery)}) {
        flex-direction: column;
        justify-content: center;
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        padding-left: 10px;
        padding-right: 10px;
    }
`;
const VideoWrapper1 = styled(Box)`
    width: 512px;
    position: relative;
    margin-left: auto !important;
    margin-right: auto !important;
    &:before,
    &:after {
        display: inline-block;
        position: absolute;
        background-repeat: no-repeat;
    }
    &:before {
        content: '2009____';
        transform: rotate(270deg);
        top: calc(50% - 30px);
        left: -70px;
        font-size: ${props => props.theme.fontSizeElements.body1}px;
        font-weight: bold;
    }
    &:after {
        content: ' ';
        width: 85px;
        height: 95px;
        left: calc(10px - 85px);
        top: calc(20px - 95px);
        background-image: url(./images/about/03.png);
        background-position: center center;
        background-size: contain;  
    }
    @media screen and (${props => (props.theme.breakpoints.tabletQuery)}) {
        &:before {
            top: 50%;
        }
        &:after {
            top: 30px;
            left: -100px;
        }
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        width: 320px;
        &:before,
        &:after {
            display: none;
        }
    }
`;
const VideoWrapper2 = styled(Box)`
    width: 512px;
    position: relative;
    margin-top: 90px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    &:before,
    &:after {
        display: inline-block;
        position: absolute;
        background-repeat: no-repeat;
    }
    &:before {
        content: '____2012';
        transform: rotate(90deg);
        top: calc(50% - 30px);
        right: -70px;
        font-size: ${props => props.theme.fontSizeElements.body1}px;
        font-weight: bold;
    }
    &:after {
        content: ' ';
        width: 45px;
        height: 115px;
        right: calc(30px - 45px);
        bottom: calc(65px - 115px);
        background-image: url(./images/about/04.png);
        background-position: center center;
        background-size: contain; 
    }
    @media screen and (${props => (props.theme.breakpoints.tabletQuery)}) {
        margin-top: 50px !important;
        &:before {
            top: 50%;
        }
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        width: 320px;
        margin-top: 20px !important;
        &:before {
            display: none;
        }
    }
`;

function VideoList() {
    return (
        <VideoListWrapper>
            <VideoWrapper1>
                <Video 
                    videoId='U3DnBhASP8o'
                    title={<>
                        <strong>Back in 2009, the street artist Dian</strong> created this stop-frame animation together <strong>with Kacer</strong>. It went pretty viral and won quite a few awards. It's all spray-painted, can you believe that?
                    </>}
                />
            </VideoWrapper1>
            <VideoWrapper2>
                <Video 
                    videoId='6xTVTbkSzaw'
                    title={<>
                        And then they <strong>created this one in 2012</strong>.
                    </>}
                    titlePosition='before'
                />
            </VideoWrapper2>
        </VideoListWrapper>
    );
}
  
export default VideoList;

