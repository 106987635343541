import styled from 'styled-components';
import { Flex, Box, Heading, Text } from 'rebass';
import Frame from './Frame';

const RoadmapBox = styled(Frame)`
    max-width: 330px;
    margin-bottom: 80px !important;
    h3 {
        text-transform: uppercase;
        text-align: center;
    }
    .strike {
        position: relative;
        &:after {
            content: ' ';
            position: absolute;
            top: calc(50% - 7px);
            left: -15px;
            right: -15px;
            width: 130px;
            height: 14px;
            background-image: url(./images/roadmap/01.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }
    }
    &:after {
        content: ' ';
        position: absolute;
        bottom: -60px;
        right: -10px;
        width: 30px;
        height: 60px;
        background-image: url(./images/roadmap/02.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }
    @media screen and (${props => (props.theme.breakpoints.tabletQuery)}) {
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        max-width: 220px;
        margin-left: auto !important;
        margin-right: auto !important;
    }
`;
const RoadmapListWrapper = styled(Flex)`
    display: flex;
    flex-direction: column;
    max-width: 790px;
    justify-content: space-around;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 120px !important;
    @media screen and (${props => (props.theme.breakpoints.tabletQuery)}) {
        margin-left: 60px !important;
        margin-right: 60px !important;
        margin-bottom: 100px !important;
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        margin-left: 30px !important;
        margin-right: 30px !important;
        margin-bottom: 30px !important;
    }
`;
const RoadmapItem = styled(Box)`
    display: flex;
    flex-direction: column;
    max-width: 520px;
    position: relative;
    margin-bottom: 30px !important;
    &:after {
        content: ' ';
        display: inline-block;
        position: absolute;
        top: 0px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: ${props => props.theme.colors.danger};
    }
    &:last-child {
        margin-bottom: 100px !important;
    }
    h4 {
        display: inline-block;
        padding: 15px 20px !important;
        font-size: ${props => props.theme.fontSizeElements.h4}px;
        font-family: ${props => props.theme.fonts.heading};
        border-bottom: 3px solid ${props => props.theme.colors.border};
    }
    p {
        padding: 15px 20px !important;
        font-size: ${props => props.theme.fontSizeElements.body2}px;
        line-height: 1.75em;
        text-align: justify !important;
    }
    &.left {
        align-items: flex-start;
        margin-right: auto;
        border-left: 1px solid ${props => props.theme.colors.danger};
        &:after { left: -4px; }
        h4, p { text-align: left; }
    }
    &.right {
        align-items: flex-end;
        margin-left: auto;
        border-right: 1px solid ${props => props.theme.colors.danger};
        &:after { right: -4px; }
        h4, p { text-align: right; }
    }
    @media screen and (${props => (props.theme.breakpoints.tabletQuery)}) {
        h4 {
            font-size: ${props => props.theme.fontSizeElements.h5}px;
        }
        p {
            font-size: ${props => props.theme.fontSizeElements.body1}px;
        }
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        h4 {
            font-size: ${props => props.theme.fontSizeElements.h5}px;
        }
        p {
            font-size: ${props => props.theme.fontSizeElements.caption}px;
        }
    }
`;

function RoadmapList() {
    return (
        <>
            <RoadmapBox id="roadmap">
                <Heading as='h3'>
                    <span className='strike'>Roadmap</span> &nbsp; Goals
                </Heading>
            </RoadmapBox>
            <RoadmapListWrapper>
                <RoadmapItem className='left'>
                    <Heading as='h4'>Rumpel IRL Exhibitions</Heading>
                    <Text as='p'>Our art collective is famous for its legendary events and parties. We’ll organise an exhibition in Prague for holders only, in cooperation with Cryptoportal NFT gallery in May, right after the drop. Another exhibition is planned for Q4 in NYC.</Text>
                </RoadmapItem>
                <RoadmapItem className='right'>
                    <Heading as='h4'>Utility: Rumpel Experiences</Heading>
                    <Text as='p'>Many traits will get access to specific experiences all over the world. Hint: we collaborate closely with well-known chefs, bartenders, baristas, as well as tattoo artists, designers and brands. All Rumpels will have several advantages attached to them. And we’ll create a website where the traits & utilities will be easily searchable.</Text>
                </RoadmapItem>
                <RoadmapItem className='left'>
                    <Heading as='h4'>Streetwear & Accessories</Heading>
                    <Text as='p'>Functional streetwear and accessories is something we’ve been doing for ten years. Our limited collections are usually sold out quickly. And these are the things we already have in production:</Text>
                    <Text as='p'>
                        <strong>Rumpel Skate Decks</strong> (Released: March)<br />
                        <strong>Rumpel Full-zipper Hoodie</strong> (May)<br />
                        <strong>Rumpel Essential Tees</strong> (May)<br />
                        <strong>Rumpel Rugs / Welcome Mats</strong> (May)<br />
                        <strong>Rumpel Leon Sweater</strong> (Oct)<br />
                        <strong>+ surprise drops & collabs</strong>
                    </Text>
                </RoadmapItem>
                <RoadmapItem className='right'>
                    <Heading as='h4'>Token gated e-shop</Heading>
                    <Text as='p'>First things first - we’ll need to take care of some basics, such as token gated e-shop and crypto payments.</Text>
                </RoadmapItem>
                <RoadmapItem className='left'>
                    <Heading as='h4'>Airdrops & Collabs</Heading>
                    <Text as='p'>Everything we’ll release in the NFT space and the Metaverse will have the holders-first approach. Many of our artists are preparing amazing NFT drops and Rumpel holders will be getting cool airdrops and pre-sale spots.</Text>
                </RoadmapItem>
                <RoadmapItem className='right'>
                    <Heading as='h4'>Forbidden Spot in the Metaverse</Heading>
                    <Text as='p'>The Forbidden Spot in Prague is a synonym for creativity. An art gallery, streetwear hub, formerly also a co-working space, an event venue and home of legendary parties. Our goal is to build such an experience also in the Metaverse. The business model will incentivize Rumpel NFT holders. This is already a plan - not just a shiny point on the roadmap.</Text>
                </RoadmapItem>
            </RoadmapListWrapper>
        </>
    );
}
  
export default RoadmapList;

