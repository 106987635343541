import React, { ReactElement } from 'react';
import styled from 'styled-components';
// @ts-ignore
import YouTube from 'react-youtube';
import { Box } from 'rebass';
import { Text } from 'rebass';

type Props = {
    videoId: string;
    title?: ReactElement;
    titlePosition?: "before" | "after";
};

const VideoBox = styled(Box)`
    width: 512px;
    margin-left: auto !important;
    margin-right: auto !important;
    display: flex;
    flex-direction: column;
    p {
        font-size: ${props => props.theme.fontSizeElements.body1}px;
        line-height: 1.75em;
    }
    p, p.after {
        order: 1;
        margin-top: 20px;
    }
    p.before {
        order: -1;
        margin-top: 0;
        margin-bottom: 20px;
    }
    @media screen and (${props => (props.theme.breakpoints.tabletQuery)}) {
        p {
            font-size: ${props => props.theme.fontSizeElements.caption}px;
        }
    }
    @media screen and (${props => (props.theme.breakpoints.lteTabletQuery)}) {
        p, p.after {
            order: -1;    
            margin-top: 0;
            margin-bottom: 20px;        
        }
    }
    @media screen and (${props => (props.theme.breakpoints.mobileQuery)}) {
        width: 100%;
        p {
            font-size: ${props => props.theme.fontSizeElements.caption}px;
        }
        iframe {
            width: 100% !important;
            height: 320px !important;
            object-fit: fill;
        }
    }
`;

function Video({ videoId, title, titlePosition }: Props) {
    const opts = {
        width: '510',
        height: '380',
        controls: 0,
    };
    return (
        <VideoBox as='div'>
            {/* @ts-ignore */}
            <YouTube
                videoId={videoId}
                opts={opts}
                containerClassName={'video-embeded'} 
            />
            {/* @ts-ignore */}
            {title && <Text as='p' className={titlePosition}>{title}</Text>}
        </VideoBox>
    );
}
  
export default Video;

